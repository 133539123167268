export default {
  role: {
    views: {
      list: {
        header: {
          title: "Uporabniške Skupine",
          search_placeholder: "Iskanje ...",
          add_button: "Dodaj uporabniško skupino"
        },
        table: {
          title: "Ime",
          description: "Opis",
          members_count: "Število članov",
          updated_at: "Posodobljeno",
          last_modified: "Nazadnje posodobil"
        }
      },
      details: {
        header: {
          back_button: "Seznam uporabniških skupin",
          edit_button: "Uredi uporabniško skupino",
          toggle: {
            title: "Aktivna",
            activate: "aktivirate",
            deactivate: "deaktivirate",
            description: "Skupino lahko {action} tukaj"
          }
        },
        main: {
          active: "Aktivna",
          inactive: "Neaktivna",
          overview: {
            author: "Avtor",
            created_at: "Datum nastanka",
            updated_at: "Datum posodibitve",
            last_modified: "Nazadnje posodobil"
          }
        }
      }
    },
    popup: {
      title: "Dodaj uporabniško skupino",
      fields: {
        title: "Naziv",
        name: "Ime",
        description: "Opis"
      },
      add_button: "Dodaj",
      update_button: "Posodobi"
    },
    prompts: {
      delete: {
        title: "Izbriši {name}",
        message: "Ste prepričani da si želite izbrisati uporabniško skupino {name}?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Uporabniška skupina {name} je bila ustvarjena",
      updated: "Uporabniška skupina {name} je bila posodobljena",
      deleted: "Uporabniška skupina  {name} je bila izbrisana",
      not_found: "Uporabniške skupine {name} nismo našli"
    },
    users: {
      list: {
        title: "Člani uporabniške skupine",
        table: {
          user: "Član",
          added_at: "Dodan",
          remove_button: "Odstrani"
        },
        add_button: "Dodaj uporabnike",
        empty_notice: "Uporabniška skupina nima članov."
      },
      add_drawer: {
        title: "Seznam uporabnikov",
        table: {
          name: "Ime",
          username: "Uporabniško ime",
          email: "Email"
        },
        search_placeholder: "Iskanje ...",
        users_count: "Število uporabnikov: {count}",
        cancel_button: "Prekliči",
        confirm_button: "Dodaj izbrane uporabnike",
        notify: {
          success: "Izbrani uporabniki dodani na skupino"
        }
      }
    },
    roles: {
      list: {
        title: "Uporabniške skupine",
        table: {
          user: "Ime",
          added_at: "Dodana",
          remove_button: "Odstrani"
        },
        add_button: "Dodaj uporabniške supine",
        empty_notice: "Uporabnik ni član nobene uporabniške skupine."
      },
      add_drawer: {
        title: "Uporabniške skupine",
        table: {
          name: "Ime",
          description: "Opis"
        },
        search_placeholder: "Iskanje ...",
        users_count: "Število izbranih skupin: {count}",
        cancel_button: "Prekliči",
        confirm_button: "Dodaj izbrane skupine",
        notify: {
          success: "Uporabnik dodan v izbrane skupine"
        }
      }
    }
  }
}
