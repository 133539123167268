export default {
  title: "Administracija",
  Data: {
    Modules: {
      users: {
        title: "Uporabniki",
        description: "Ustvari, izbriši in urejaj uporabnike."
      },
      userGroups: {
        title: "Uporabniške skupine",
        description: "Ustvari, izbriši in urejaj uporabniške skupine."
      },
      codelists: {
        title: "Šifranti",
        description: "Vsi vnaprej določeni ter spremenljivi šifranti v aplikaciji."
      },
      documentTemplates: {
        title: "Predloge dokumentov",
        description: "Predloge dokumentov za generiranje dokumentov v aplikaciji."
      },
      questionnaires: {
        title: "Vprašalniki",
        description: "Ustvari in urejaj vprašalnike."
      }
    }
  }
}
