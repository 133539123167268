<template>
    <nav class="navigation">
        <div class="navigation-top">
            <div class="navigation-top__container">
                <div class="navigation-top__left">
                    <Logo class="navigation-top__logo" @click.native="$router.push('/')"/>
                    <div class="navigation-top__links">
                        <router-link
                            v-for="link in $options.links"
                            :key="link.route"
                            :to="link.route"
                            class="navigation-top__link"
                            :title="link.title"
                        >
                            <div
                                class="navigation-top__link-icon"
                                :class="{'navigation-top__link-icon--highlight': getCount(link.route)}"
                            >
                                <UikIcon :icon="['fad', link.icon]"/>
                                <div
                                    v-if="getCount(link.route)"
                                    class="navigation-top__link-count"
                                >
                                    {{ getCount(link.route) }}
                                </div>
                            </div>
                            <span>{{ link.title }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="navigation-top__right">
                    <div class="navigation-top__user">
                        <UikDropdown v-model="userDropdown">
                            <UikDropdownItem
                                @click.native="
                                    $router.push('/settings');
                                    userDropdown = false;
                                    openNav = false;
                                "
                            >
                                <UikIcon :icon="['far', 'cog']"/>Nastavitve
                            </UikDropdownItem>
                            <UikDropdownItem
                                @click.native="
                                    userDropdown = false;
                                    openNav = false;
                                    $api.Auth.logout();
                                "
                            >
                                <UikIcon
                                    :icon="['far', 'sign-out-alt']"
                                />Odjava
                            </UikDropdownItem>
                        </UikDropdown>
                        <button
                            class="navigation-top__avatar-container"
                            :class="{'navigation-top__avatar-container--active': userDropdown || $route.path === '/settings'}"
                            @click="userDropdown = !userDropdown"
                        >
                            <UikAvatar
                                :name="user.name ? user.name : 'Povezovanje ...'"
                                :imageUrl="user.avatar_url ? user.avatar_url : ''"
                            />
                            <UikIcon :icon="['fas', 'sort-down']"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Logo from "@/Assets/Logo.vue"

export default {
  links: [
    {
      route: "/tasks",
      title: "Opravila",
      icon: "bolt"
    },
    {
      route: "/posts",
      title: "Vsebine",
      icon: "newspaper"
    },
    {
      route: "/my-posts",
      title: "Moje vsebine",
      icon: "user-graduate"
    },
    {
      route: "/administration",
      title: "Administracija",
      icon: "sliders-h"
    }
  ],
  components: { Logo },
  data () {
    return {
      userDropdown: false
    }
  },
  computed: {
    user () {
      return this.$api.Auth && this.$api.Auth.state && this.$api.Auth.state.user
        ? this.$api.Auth.state.user
        : {}
    },
    userPermissions () {
      if (!this.user.permissions) return []
      return this.user.permissions
    }
  },
  created () {
    this.$api.GlobalPosts.getCount()
  },
  methods: {
    getCount (route) {
      let count = null
      if (route === "/tasks") {
      // eslint-disable-next-line camelcase
        count = this.$api.GlobalPosts?.count?.unread_tasks
        if (count > 99) return "99+"
      }
      return count
    }
  }
}
</script>

<style lang="scss">
.navigation {
  $navigationHeight: 80px;
  .navigation-top {
    width: 100%;
    height: $navigationHeight;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: $boxShadow;
    background: rgba($bg, 0.8);
    backdrop-filter: blur(10px);
    .navigation-top__container {
      width: 100%;
      max-width: 1440px;
      padding: 0 20px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      > * {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      }
      .navigation-top__logo {
        height: 24px;
        cursor: pointer;
        .st0 {
          transition: fill 0.125s;
        }
        &:hover {
          .st0 {
            fill: $primary;
          }
        }
      }
      .navigation-top__links {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin-left: 38px;
        .navigation-top__link {
          padding: 0 18px;
          height: 46px;
          transition: all 0.2s;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 99px;
          .navigation-top__link-icon {
            position: relative;
            svg {
              font-size: 18px;
              margin-right: 10px;
              color: $textSecondary;
              transition: all 0.125s;
              --fa-secondary-opacity: 0.75;
            }
            .navigation-top__link-count {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 10px;
              line-height: 1;
              font-weight: 500;
              color: white;
              padding: 0 3px;
              height: 15px;
              border-radius: 4px;
              background: #d93636;
              position: absolute;
              top: -12px;
              left: 4px;
            }
            &--highlight {
              svg {
                color: $primary;
              }
            }
          }
          span {
            font-size: 14px;
            font-weight: 700;
            color: rgba($textMain, 0.5);
            transition: all 0.125s;
            white-space: nowrap;
          }
          & + .navigation-top__link {
            margin-left: 10px;
          }
          &:hover {
            background: rgba($textSecondary, 0.25);
            box-shadow: 0 5px 10px -5px transparent, inset 0 5px 10px -5px transparent;
            border-top-right-radius: 0;
            transition: all 0.2s, background-color 0.15s;
            span {
              color: $textMain;
            }
            svg {
              color: $primary;
            }
          }
          &:active {
            background: rgba($textSecondary, 0.4);
            box-shadow: 0 5px 10px -5px transparent, inset 0 5px 10px -5px transparent;
            border-top-right-radius: 0;
            span {
              color: $textMain;
            }
            svg {
              color: $primary;
            }
          }
          &.router-link-active,
          &.router-link-exact-active {
            border-top-right-radius: 0;
            background: white;
            box-shadow: 0 2px 15px -10px rgba($textMain, 0.5);
            cursor: default;
            animation: navigation-active 0.2s;
            @keyframes navigation-active {
              from {
                box-shadow: none;
              }
            }
            span {
              color: $textMain;
            }
            svg {
              color: $primary;
            }
          }
        }
      }
      .navigation-top__user {
        position: relative;
        .navigation-top__avatar-container {
          padding: 7px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          background: transparent;
          border: solid 1px rgba($textSecondary, 0.35);
          border-radius: 99px;
          border-top-right-radius: 0;
          transition: all 0.125s;
          .uik-avatar__wrapper {
            .uik-avatar__avatarWrapper {
              width: 30px;
              height: 30px;
              .uik-avatar__avatarPlaceholder {
                border: none;
                background: $primary;
                color: white;
                font-size: 12px;
              }
            }
            .uik-avatar__info {
              margin-left: 8px;
              padding-right: 8px;
              .uik-avatar__name {
                font-weight: 600;
                color: rgba($textMain, 0.9);
                font-size: 14px;
                line-height: 20px;
                transition: all 0.125s;
                display: block;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          > svg {
            font-size: 14px;
            color: $textSecondary;
            margin: -5px 5px 0 0;
            transition: all 0.125s;
          }
          &:hover {
            background: rgba($textSecondary, 0.25);
            border-color: transparent;
            cursor: pointer;
            box-shadow: none;
            .uik-avatar__wrapper {
              .uik-avatar__info {
                .uik-avatar__name {
                  color: $textMain;
                }
              }
            }
            > svg {
              color: $primary;
            }
          }
          &:active {
            background: rgba($textSecondary, 0.4);
            border-color: transparent;
            .uik-avatar__wrapper {
              .uik-avatar__info {
                .uik-avatar__name {
                  color: $textMain;
                }
              }
            }
          }
          &--active {
            border-color: transparent;
            background: white;
            box-shadow: 0 2px 15px -10px rgba($textMain, 0.5);
            .uik-avatar__wrapper {
              .uik-avatar__info {
                .uik-avatar__name {
                  color: $textMain;
                }
              }
            }
            > svg {
              color: $textBase;
            }
          }
        }
        .uik-menuDrop {
            .uik-menuDrop__list {
              right: 0;
              top: 30px;
              width: 160px;
            }
          }
      }
    }
  }
  & + * {
    padding-top: $navigationHeight !important;
  }
}
</style>
