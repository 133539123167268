import LaravelResourceRepository from "@/providers/api/repositories/LaravelResourceRepository"
import Vue from "vue"
const ApiUrl = process.env.VUE_APP_API

export default class Post extends LaravelResourceRepository {
  route = "posts"
  namespace = "posts"
  identifier = "uid"

  state = {
    listDefaultParams: {
      with: ["createdBy", "updatedBy", "category", "questionnaire"]
    },
    detailsDefaultParams: {
      with: ["createdBy", "updatedBy", "category", "questionnaire", "media"]
    },
    tasks: null,
    count: null
  }

  actions = {
    async getTasks ({ state, commit }) {
      try {
        commit("SET_LOADER", { type: "tasks", value: true })
        let promise = await window.axios.get(`${ApiUrl}${state.route}/get-tasks`)
        commit("SET_TASKS", promise.data)
        commit("SET_LOADER", { type: "tasks", value: false })
        return { status: promise.status, data: promise.data }
      } catch (error) {
        commit("SET_LOADER", { type: "tasks", value: false })
        return { ...error.response }
      }
    },
    async getCount ({ state, commit }) {
      try {
        commit("SET_LOADER", { type: "count", value: true })
        let promise = await window.axios.get(`${ApiUrl}${state.route}/get-count`)
        commit("SET_COUNT", promise.data)
        commit("SET_LOADER", { type: "count", value: false })
        return { status: promise.status, data: promise.data }
      } catch (error) {
        commit("SET_LOADER", { type: "count", value: false })
        return { ...error.response }
      }
    },
    async getComments ({ state, commit }, { id, params }) {
      try {
        commit("SET_LOADER", { type: "comments", value: true })
        let promise = await window.axios.get(`${ApiUrl}${state.route}/${id}/get-comments`, { params: {
          per_page: params.per_page,
          page: params.page
        } })
        commit("SET_COMMENTS", { payload: promise.data, replace: params.replace })
        commit("SET_LOADER", { type: "comments", value: false })
        return { status: promise.status, data: promise.data }
      } catch (error) {
        commit("SET_LOADER", { type: "comments", value: false })
        return { ...error.response }
      }
    },
    clearComments ({ state, commit }) {
      commit("CLEAR_COMMENTS")
    },
    async storeComment ({ state, commit }, { id, params }) {
      try {
        commit("SET_LOADER", { type: "storeComment", value: true })
        let promise = await window.axios.post(`${ApiUrl}${state.route}/${id}/store-comment`, { ...params })
        commit("SET_LOADER", { type: "storeComment", value: false })
        return { status: promise.status, data: promise.data }
      } catch (error) {
        commit("SET_LOADER", { type: "storeComment", value: false })
        return { ...error.response }
      }
    },
    async getQuestionnaireResults ({ state, commit }, { id, params }) {
      try {
        commit("SET_LOADER", { type: "questionnaireResults", value: true })
        let promise = await window.axios.get(`${ApiUrl}${state.route}/${id}/get-questionnaire-results`)
        commit("SET_LOADER", { type: "questionnaireResults", value: false })
        return { status: promise.status, data: promise.data }
      } catch (error) {
        commit("SET_LOADER", { type: "questionnaireResults", value: false })
        return { ...error.response }
      }
    }
  }

  mutations = {
    SET_TASKS (state, value) {
      Vue.set(state, "tasks", value)
    },
    SET_COUNT (state, value) {
      Vue.set(state, "count", value)
    },
    SET_COMMENTS (state, { payload, replace }) {
      let value = JSON.parse(JSON.stringify(payload))
      if (!replace && !!state.details.comments) {
        value.data = [
          ...state.details.comments.data,
          ...value.data
        ]
      }
      Vue.set(state.details, "comments", value)
    },
    CLEAR_COMMENTS (state) {
      Vue.delete(state.details, "comments")
    }
  }
}
