export default {
  views: {
    list: {
      header: {
        title: "Media",
        search_placeholder: "Search ...",
        add_button: "Add media"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Media list",
        edit_button: "Edit media",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} media here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    }
  },
  popup: {
    title: "New Media",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add media",
    update_button: "Update media"
  },
  prompts: {
    delete: {
      title: "Izbris datoteke {name}",
      message: "Ste prepričani da želite izbrisati datoteko {name}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Datoteka {title} je bila ustvarjena",
    updated: "Datoteka {title} je bila posodobljena",
    deleted: "Datoteka {title} je bila izbrisana",
    not_found: "Datoteke ni mogoče najti"
  },
  widget: {
    title: "Datoteke",
    upload: "Naloži datoteko",
    generate: "Generiraj iz predloge",
    table: {
      title: "Naziv",
      collection: "Collection",
      remove_button: "Izbriši"
    }
  }
}
