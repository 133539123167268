import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/posts",
    name: "Posts",
    meta: { title: "Vsebine" },
    component: () => import(/* webpackChunkName: "posts" */ "@/Modules/Posts/Views/Posts"),
    children: [
      {
        path: "details/:id",
        name: "Post Details"
      },
      {
        path: "edit/:id",
        name: "Edit Post"
      },
      {
        path: "create",
        name: "New Post",
        meta: { title: "Nova objava" }
      },
      {
        path: "details/:id/results",
        name: "Post Questionnaire Results"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/tasks",
    name: "Tasks",
    meta: { title: "Opravila" },
    component: () => import(/* webpackChunkName: "tasks" */ "@/Modules/Posts/Views/Tasks"),
    children: [
      {
        path: "details/:id",
        name: "Post Details"
      },
      {
        path: "edit/:id",
        name: "Edit Post"
      },
      {
        path: "create",
        name: "New Post",
        meta: { title: "Nova objava" }
      },
      {
        path: "details/:id/results",
        name: "Post Questionnaire Results"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/my-posts",
    name: "My Posts",
    meta: { title: "Moje vsebine" },
    component: () => import(/* webpackChunkName: "tasks" */ "@/Modules/Posts/Views/MyPosts"),
    children: [
      {
        path: "edit/:id",
        name: "Edit Post"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/posts/questionnaires/:id/:id_questionnaire",
    name: "Post Fill Questionnaire",
    meta: { title: "Izpolni vprašalnik" },
    component: () => import(/* webpackChunkName: "Questionnaire" */ "@/Modules/Questionnaire/Views/Overview/Details.vue"),
    beforeEnter: ifAuthenticated
  }
]
