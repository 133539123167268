import Vue from "vue"
import store from "./providers/store"
import router from "./providers/router"
import App from "./App.vue"

import ResourcesRegistration from "./providers/mixins/ResourcesRegistration"
import FilterOptions from "./providers/mixins/FilterOptions"
import Codelists from "./providers/mixins/Codelists"
import SetUi from "./providers/mixins/SetUi"
import SetTitle from "./providers/mixins/SetTitle"

import "./bootstrap.js"

Vue.config.productionTip = false

store.dispatch("init")

new Vue({
  mixins: [ResourcesRegistration, FilterOptions, Codelists, SetUi, SetTitle],
  router,
  store,
  data () {
    return {
      appPath: process.env.VUE_APP_URL,
      appStorage: process.env.VUE_APP_URL + "storage",
      uik: {
        icon: {
          type: "far"
        },
        datepicker: {
          valueFormat: "YYYY-MM-DD HH:mm:ss",
          lang: "sl"
        }
      }
    }
  },
  render: (h: any) => h(App)
}).$mount("#app")
