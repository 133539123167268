export default {
  views: {
    list: {
      header: {
        title: "Predloge dokumentov",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj predlogo"
      },
      table: {
        title: "Ime predloge",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Nazadnje posodobil",
        active: "Aktivna"
      }
    },
    category: {
      title: "Kategorije",
      edit: "Uredi kategorijo",
      new: "Dodaj kategorijo",
      save: "Shrani",
      delete: "Izbriši",
      cancel: "Prekliči",
      edit2: "Uredi",
      fields: {
        title: "Naziv"
      }
    },
    details: {
      header: {
        back_button: "Document Templates list",
        edit_button: "Edit document template",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} document template here"
        }
      },
      upload: {
        file: "Upload file"
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    },
    edit: {
      header: {
        remove_button: "Delete",
        back_button: "Na listo predlog dokumentov"
      },
      tabs: {
        properties: "Lastnosti",
        scope: "Podatki"
      },
      upload: {
        file: "Naložite datoteko"
      },
      footer: {
        save_button: "Shrani"
      },
      properties: {
        title: "Ime predloge dokumenta",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        file: "Datoteka",
        category: "Kategorija",
        view: "Modul predloge dokumenta",
        allowed_export_formats: "Dovoljeni izvozni formati"
      },
      scope: {
        table: {
          model: "Model",
          feature: "Funkcija",
          response_key: "Ključ"
        },
        add_model: "Dodaj model",
        add_relation: "Dodaj relacijo"
      }
    }
  },
  popup: {
    title: "Nova predloga dokumentov",
    fields: {
      title: {
        label: "Naziv"
      }
    },
    add_button: "Shrani",
    update_button: "Shrani"
  },
  prompts: {
    delete: {
      title: "Izbris predloge {title}",
      message: "Ste prepričani da želite izbrisati predlogo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili predlogo dokumentov {title}",
    updated: "Uspešno ste posodobili predlogo dokumentov {title}",
    deleted: "Uspešno ste izbrisali predlogo dokumentov {title}",
    not_found: "Predloga dokumentov ni bila najdena"
  }
}
