export default {
  views: {
    list: {
      header: {
        title: "Posts",
        search_placeholder: "Search ...",
        add_button: "Add post"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Posts list",
        edit_button: "Edit post",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} post here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      },
      questionnaire_results: {
        title: "Rezultati meritve",
        unit: "Oddelek",
        employees: "Št. zaposlenih",
        closed: "Opravljeno",
        open: "Neopravljeno",
        toggle: {
          data: "Podatki",
          show_data: "Prikaži podatke kandidatov",
          answers: "Odgovori",
          show_answers: "Prikaži odgovore"
        },
        buttons: {
          export_excel: "Izvoz rezultatov"
        },
        person: "Oseba",
        duration: "Čas reševanja",
        status: "Stanje",
        average: "M",
        sd: "SD",
        leader: "Vodja oddelka",
        tag: {
          closed: "Opravljeno",
          open: "Neopravljeno"
        },
        total: "Skupaj",
        gender: {
          male: "Moški",
          female: "Ženska"
        }
      }
    }
  },
  popup: {
    title: "New Post",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add post",
    update_button: "Update post"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Post {title} created",
    updated: "Post {title} updated",
    deleted: "Post {title} was deleted",
    not_found: "Unable to find specified post"
  }
}
