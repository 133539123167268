export default {
  user: {
    views: {
      list: {
        header: {
          title: "Uporabniki",
          search_placeholder: "Iskanje ...",
          add_button: "Nov uporabnik"
        },
        table: {
          title: "Ime in priimek",
          created_at: "Datum nastanka",
          updated_at: "Datum posodobitve",
          author: "Avtor",
          last_modified: "Nazadnje posodobljen",
          active: "Aktiven"
        }
      },
      details: {
        header: {
          back_button: "Seznam uporabnikov",
          edit_button: "Uredi uporabnika",
          toggle: {
            title: "Aktiven",
            activate: "aktivirate",
            deactivate: "deaktivirate",
            description: "Uporabnika lahko {action} tukaj"
          }
        },
        main: {
          active: "Aktiven",
          inactive: "Neaktiven",
          overview: {
            author: "Avtor",
            created_at: "Datum nastanka",
            updated_at: "Datum posodobitve",
            last_modified: "Nazadnje posodobljen",
            address: "Naslov in hišna številka",
            postal: "Poštni naslov",
            country: "Država",
            phone: "Telefonska številka",
            position_title: "Naziv pozicije",
            position_department: "Naziv oddelka"
          }
        },
        resetPassword: {
          title: "Sprememba gesla",
          subtitle: "Varnost in prijava",
          form: {
            currentPassword: "Trenutno geslo",
            newPassword: "Novo geslo",
            changePassword: "Spremeni geslo",
            passwordRule: "Geslo mora vsebovati najmanj 6 znakov, vsaj eno črko, številko in poseben znak"
          },
          feedback: {
            succcess: "Vaše geslo je bilo uspešno spremenjeno!",
            changeItAgain: "Ponovno spremeni?"
          }
        }
      }
    },
    popup: {
      title: "Nov uporabnik",
      fields: {
        name: "Ime in priimek uporabnika",
        username: "Uporabniško ime",
        email: "Elektronski naslov",
        address: "Naslov in hišna številka",
        postal: "Poštni naslov",
        country: "Država",
        phone: "Telefonska številka",
        position_title: "Naziv pozicije",
        position_department: "Naziv oddelka"
      },
      add_button: "Shrani uporabnika",
      update_button: "Shrani uporabnika"
    },
    prompts: {
      delete: {
        title: "Izbris uporabnika {name}?",
        message: "Ste prepričani da želite izbrisati uporabnika {name}?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Uspešno ste shranili uporabnika {name}",
      updated: "Uspešno ste posodobili uporabnika {name}",
      deleted: "Uspešno ste izbrisali uporabnika {name}",
      not_found: "Uporabnik ni bil najden"
    }
  },
  permissions: {
    title: "Pravice",
    add_button: "Dodaj pravico",
    set_scope_for: "Nastavi obseg za",
    table: {
      columns: {
        permission: "Pravica",
        module: "Modul"
      },
      remove_button: "Odstrani pravico"
    },
    empty_notice: "Ni več pravic za {name}.",
    scope_drawer: {
      title: "Dodaj obseg za {name}",
      cancel_button: "Prekliči",
      confirm_button: "Nastavi obseg"
    },
    add_drawer: {
      title: "Add permissions to {name}",
      search_placeholder: "Search ...",
      permissions_count: "{count} selected",
      table: {
        columns: {
          permission: "Permission",
          module: "Module"
        }
      },
      cancel_button: "Cancel",
      confirm_button: "Add selected permissions",
      notify: {
        success: "{count} permissions added to {name}",
        error: "An error occured while adding permissions to {name}"
      }
    }
  }
}
